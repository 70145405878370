import { useEffect, useState } from "react";
import useAuth from "../../context/AuthProvider";
import { StateReportModel_Resp, StateReportModel } from "../../models/StateReportModel";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectProps, TextField } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { config } from "../../Constants";
import Swal from "sweetalert2";
import { FMTextField } from "../Elements/input";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';


interface Iprops {
    StateRuleMatrixCL: StateReportModel_Resp,
    handleClose: () => void,
    Open: boolean,
    isStateReport: boolean,
    handleSubmit: () => void
    initValues: StateReportModel_Resp
    //selectedButton:number
}

export const EditStateReport: React.FC<Iprops> = ({ handleClose, Open, StateRuleMatrixCL, handleSubmit, initValues,isStateReport}) => {
    //const initialValues: StateReportModel_Resp = initValues ? StateRuleMatrixCL : initValues;
    const initialValues: StateReportModel_Resp = {
        ...StateRuleMatrixCL,
        largeReport: StateRuleMatrixCL.largeReport  // Keep it as is: null, true, or false
    };  
    

    const { user } = useAuth();
    const token = user?.token;

    useEffect(() => {
        // fetchStateData();
    }, [])
    return (

        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className='card-title' >State Report</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(data, { setSubmitting }) => {
                    data.partnerId = data.partnersId
                    //data.largeReport = data.largeReport.toLowerCase() == 'true' ? true : false
                    data.largeReport = String(data.largeReport).toLowerCase() === 'true';

                    fetch(
                        config.url.API_URL + '/StateReports/AddEditStateReport',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify({ 
                                ...data,
                              })

                        })
                        .then((response) => {
                            if (response.ok) {
                                
                                return response.json();
                                
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            handleSubmit()
                            setSubmitting(true)
                            Swal.fire({
                                title: "Data Successfully saved",
                                text: "",
                                icon: "success",
                                confirmButtonText: "OK",
                            });
                            window.location.reload();
                          
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue, errors }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="licensee" autoFocus margin="dense" disabled label="Licensee" placeholder="Licensee" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }}>
                                        {isStateReport ? (
                                            <FMTextField
                                                name="client"
                                                autoFocus
                                                margin="dense"
                                                disabled
                                                label="Client"
                                                placeholder="client"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ m: 2, width: '100%' }}
                                            />
                                        ) : (
                                            <FMTextField
                                                name="partnerName"
                                                autoFocus
                                                margin="dense"
                                                disabled
                                                label="Client"
                                                placeholder="client"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ m: 2, width: '100%' }}
                                            />
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="licenseNumber" autoFocus margin="dense" disabled  label="LicenseNumber" placeholder="LicenseNumber" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        {isStateReport ? ( <FMTextField name="report" autoFocus margin="dense" disabled  label="report" placeholder="report" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                        ):  ( <FMTextField name="reportName" autoFocus margin="dense" disabled  label="report" placeholder="report" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="payment" autoFocus margin="dense" disabled  label="payment" placeholder="payment" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="dueDate" autoFocus margin="dense" disabled  label="DueDate" placeholder="DueDate" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                            
                                {isStateReport && (<Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="status" autoFocus margin="dense" disabled label="Status" placeholder="Status" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>)}

                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="paymentType" autoFocus margin="dense" disabled label="paymentType" placeholder="paymentType" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                {isStateReport && (<Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="notesToClient" autoFocus margin="dense"  label="Notes To Client" placeholder="NotesToClient" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>)}
                                {isStateReport && (<Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="taxReported" autoFocus margin="dense"  label="Tax Reported" placeholder="Tax Reported" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>)}
                                {isStateReport && (<Grid item xs={6}>
                                    {/* <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="large-report">Large Report</InputLabel>
                                    <Select
                                        labelId="large-report"
                                        name="largeReport"
                                        // Check if initialValues.largeReport is defined and a string; if not, provide a default empty string
                                        value={typeof initialValues.largeReport === 'string' ? initialValues.largeReport.toUpperCase() : ''}
                                        onChange={(event) => {
                                            setFieldValue("largeReport", event.target.value);
                                            initialValues.largeReport = event.target.value;
                                        }}
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ m: 0.2 }}
                                    >
                                        <MenuItem value="TRUE">TRUE</MenuItem>
                                        <MenuItem value="FALSE">FALSE</MenuItem>
                                    </Select>
                                    </FormControl> */}
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="large-report">Large Report</InputLabel>
                                        <Select
                                            labelId="large-report"
                                            name="largeReport"
                                            value={values.largeReport ? String(values.largeReport).toUpperCase() : ''}  // Fallback to '' if null or undefined
                                            onChange={(event) => setFieldValue("largeReport", event.target.value)}
                                            margin="dense"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ m: 0.2 }}
                                        >
                                            <MenuItem value="TRUE">TRUE</MenuItem>
                                            <MenuItem value="FALSE">FALSE</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>)}
                                {!isStateReport &&(<Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="largeReportFee" autoFocus margin="dense"  label="Large Report" placeholder="LargeReport" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>)}
                             
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}  >Cancel</button>
                            <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
