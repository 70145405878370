import { Box, Button, IconButton, LinearProgress, Link, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { LoggedInUserInfo } from "../../components/Elements/loggedInUserInfo";
import { RightNav } from "../../components/Nav/RightNav";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";

import { DataGrid, getGridStringOperators, GridColDef, GridColumnMenuContainer, GridColumnMenuProps, GridColumnsMenuItem, GridFilterMenuItem, GridFilterModel, GridFilterOperator, GridRowId, GridSelectionModel, GridSortModel, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, HideGridColMenuItem, SortGridMenuItems } from "@mui/x-data-grid";
import React from "react";
import { PolicyDocumentsPopup } from "./PolicyDocumentsPopup";
import moment from "moment";
import { RiExpandUpDownLine } from "react-icons/ri";
import { FMFilters, getCompletedFilings__Resp, NewCompletedFilingsModel } from "../../models/NewCompletedFilingsModel";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Stack, width } from "@mui/system";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { MenuDropdown } from "../../components/Elements/MenuDropdown";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import formatPrice from "../../helper/currencyFormatter";
import { getRowsPerPageOptions } from "../../components/AddEdit/CustomFunctions";
import { FMTabs } from "../Tabs";
import { apiClient } from "../../helper/api";
interface CompletedFilingsModel {
  customerPolicyId: number,
  carrier: string,
  effectiveDate: string,
  policyNumber: string,
  namedInsured: string,
  state: string,
  doucmentsUploaded: number,
  error: string,
  customerName: string,
  errorsCount: 0,
  slNo?: number,
  policyId: number,
  transactionType?: string,
  dateCreated?: string,
  status?: string

}


interface Iprops {
  NewCompletedFilingsCL: getCompletedFilings__Resp;
  fetchDataMain: (
    action: string,
    newValue: number,
    pageSize: number,
    currentPage: number
  ) => void;
  closePolicy: (
    custPolicyId: number,
    action: string,
    value: number,
    message: string,
    isClosed: boolean,
    isRestoreToRec: boolean
  ) => void;
  fetchCompletedDataSorting: (
    action: string,
    newValue: number,
    pageSize: number,
    currentPage: number,
    field: string,
    sort?: string
  ) => void;
  getCustomerPolicyListbyTaxType: (
    action: string,
    customerPolicyIds: number[],
    option: string
  ) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  _currentPage: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  _pageSize: number
  debouncedOnFilterChange: ((filterModel: GridFilterModel, tabValue: number) => void)
  fetchDataForFilter: (action: string, newValue: number, pageSize: number, pageNumber: number, newFilters?: FMFilters) => void;
  queryOptions: FMFilters | undefined
  sortModelRef: React.MutableRefObject<GridSortModel>
}

export const CompletedFilings: React.FC<Iprops> = ({
  NewCompletedFilingsCL,
  fetchDataMain,
  closePolicy,
  fetchCompletedDataSorting,
  getCustomerPolicyListbyTaxType,
  setCurrentPage,
  _currentPage,
  setPageSize,
  _pageSize,
  debouncedOnFilterChange,
  fetchDataForFilter,
  queryOptions,
  sortModelRef

}) => {
  const { user } = useAuth();
  const token = user?.token;
  const [loading, setloading] = useState(false);
  const [Formdata, setFormData] = React.useState([]);
  const [Open, setOpen] = React.useState(false);
  const [OpenError, setOpenError] = React.useState(false);
  const [OpenDocResubmission, setDocResubmission] = React.useState(false);
  // const [_currentPage, setCurrentPage] = React.useState(1);
  // const [_pageSize, setPageSize] = React.useState(50);

  const [selectedState, setselectedState] = useState("");


  const handleEdit = (custPolId: number) => {
    apiClient(`/PolicyDashBoard/getCustomerPolicyDocumentDetails?CustPolicyId=${custPolId}`,
      {
        method: "GET",
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        })
      })
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response
        console.log(responseJson);
        setFormData(responseJson.data);
      })

    setOpen(true);
  };
  const handleClose = () => {
    setFormData([]);
    setOpen(false);
    setOpenError(false);
    setDocResubmission(false);


  };

  const handleNextPreviousPage = (newPage: number) => {
    setCurrentPage(newPage);

    var field = sortModelRef.current[0]?.field ?? '';
    var sort = sortModelRef.current[0]?.sort ?? '';
    // fetchCompletedDataSorting("getClosedFilings", 5, _pageSize, newPage,field,sort);
    fetchDataForFilter("getClosedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, newPage, queryOptions)
  };

  const handlePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);

    var field = sortModelRef.current[0]?.field ?? '';
    var sort = sortModelRef.current[0]?.sort ?? '';
    // fetchCompletedDataSorting("getClosedFilings", 5, newPageSize, _currentPage,field,sort);
    fetchDataForFilter("getClosedFilingsFilterData", FMTabs.CompletedFiling, newPageSize, _currentPage, queryOptions)
  };

  useEffect(() => {
    console.log("userrrrrrrrrrrrrrrrrrrrrrr",user?.userRoles)
  }, [])

  const initialdata: NewCompletedFilingsModel = {
    customerpolicyid: 0,
    homeState: "",
    policyId: 0,
    dueDate: ""
  }

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,);
  },
    [],
  );

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    debouncedOnFilterChange(filterModel, FMTabs.CompletedFiling);
  }, []);
  const handlehandleApprove = (custPolId: number) => {
    setloading(true);
    apiClient(`/PolicyDashBoard/Approve?custPolId=${custPolId}`,
        {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            })
        })
        .then((responseJson) => {
            if (!responseJson) return
            // Do something with the response
            setloading(false);
            //fetch data
            // fetchDataMain("getFlagsPendingReadyReviewFilings", 6, _pageSize, _currentPage);
            fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.ReadyToFiling, _pageSize, _currentPage, queryOptions)
            setloading(false);

        })
        .finally(() => {
            setloading(false);
        });
};

  const filterOperators = getGridStringOperators().filter(({ value }) => ['contains'].includes(value));
  const equalOperators: GridFilterOperator<any, string | number | null, any>[] =  getGridStringOperators().filter(({ value }) => ['equals'].includes(value));

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({ row }) => <DetailPanelContent row={row} />, []);
  const getDetailPanelHeight = React.useCallback(() => 200, []);
  function DetailPanelContent({ row }: { row: NewCompletedFilingsModel }) {
    const [TaxNamAndData, setTaxNamAndData] = useState<NewCompletedFilingsModel[]>([initialdata]);
    const TaxNameAndDate = () => {

      setTaxNamAndData([])
      apiClient(`/PolicyDashBoard/getaxTypeandDateByCustpolicyid?customerpolicyId=${row.customerpolicyid}`,
        {
          method: "GET",
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          })
        })
        .then((responseJson) => {
          // Do something with the response
          if (responseJson?.data != null) {

            setTaxNamAndData(responseJson.data)
            console.log("taxData", responseJson)

          }


        })
    }
    useEffect(() => {
      TaxNameAndDate();
    }, [row])

    const rows = [
      {
        TaxNamAndData
      }
    ];
    return (

      <Stack direction="column" spacing={1} sx={{ height: 1 }} className="hide-border-12" >

        <DataGridPro
          columns={[
            {
              field: 'taxType',
              type: 'string',
              headerName: 'Tax Type',
              flex: 1,
              headerAlign: "center",
              hideSortIcons: true,
              renderHeader: (params) => (
                <div>
                  <span className="table-card-title" >{params.colDef.headerName}</span>
                  <RiExpandUpDownLine />
                </div>
              ),
              align: "center",
            },

            {
              field: 'taxAmt', hideSortIcons: true,
              renderHeader: (params) => (
                <div>
                  <span className="table-card-title" >{params.colDef.headerName}</span>
                  <RiExpandUpDownLine />
                </div>
              ), type: 'string', headerName: 'Tax Amount', flex: 0.5, align: 'center', headerAlign: 'center',
              renderCell: (params) => {
                const value = params.value || '0'; // Default to '0' if value is null or undefined
                return (
                  <Box display="flex" alignItems="left" justifyContent="left">

                    {formatPrice(value)}

                  </Box>
                )
              }

            },


          ]}
          rows={TaxNamAndData}
          getRowId={(r) => r?.rowNum ?? 0}
          sx={{ flex: 1 }}
          hideFooter
        />
      </Stack>
    );
  }



  const columns: GridColDef[] = [
    {
      field: 'dueDate', hideSortIcons: true,
      renderHeader: (params) => (
        <div className="d-flex align-items-center" >
          <span className="table-card-title word-wrap-1 p-0 " >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ), type: 'string', headerName: 'Filing Due Date', flex: 0.5, align: 'center', headerAlign: 'center',
      valueGetter: (params) =>
      (
        new Date(params.row.dueDate)
      ),
      renderCell: (params) =>
      (
        <div>
          {(params.row.dueDate != null && params.row.dueDate != '') ? moment(params.row.dueDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },
    {
      field: 'agency', hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ), type: 'string', headerName: 'Client', flex: 0.35, align: 'center', headerAlign: 'center' , filterOperators
    },
    {
      field: 'invoiceNumber', hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ), type: 'string', headerName: 'Invoice Number', flex: 0.68, align: 'center', headerAlign: 'center' , filterOperators
    },

    {
      field: 'insuredName',
      type: 'string',
      headerName: 'Insured Name',
      flex: .6,
      headerAlign: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      align: "center",
      filterOperators,
    },
    {
      field: 'policyNumber',
      type: 'string',
      headerName: 'Policy Number',
      flex: 0.65,
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      headerAlign: "center",
      align: "center",
      filterOperators,
    },

    {
      field: "homeState",
      type: "string",
      headerName: "Home State",
      flex: 0.55,
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      filterOperators,
    },

    {
      field: "transactionTypeName",
      type: "string",
      headerName: "Transaction Type",
      flex: 0.7, hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      filterOperators,
    },
    
    {
      field: 'invoiceDate', hideSortIcons: true,
      renderHeader: (params) => (
          <div>
              <span className="table-card-title " >{params.colDef.headerName}</span>
              <RiExpandUpDownLine />
          </div>
      ), type: 'string', headerName: 'Invoice Date', flex: 0.7, align: 'center', headerAlign: 'center', filterOperators: equalOperators, 
      renderCell: (params) =>
      (
          <div>
              {(params.row.invoiceDate != null && params.row.invoiceDate != '') ? moment(params.row.invoiceDate).format("MM/DD/YYYY") : ""}
          </div>
      )
  },
    {
      field: 'effectiveDate', hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title " >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ), type: 'string', headerName: 'Policy Effective Date', flex: 0.7, align: 'center', headerAlign: 'center', filterOperators: equalOperators, 
      renderCell: (params) =>
      (
        <div>
          {(params.row.effectiveDate != null && params.row.effectiveDate != '') ? moment(params.row.effectiveDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },

    {
      field: 'policyExpDate', hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title " >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ), type: 'string', headerName: 'Policy Expiration Date', flex: 0.7, align: 'center', filterOperators: equalOperators, 

      headerAlign: 'center', renderCell: (params) =>
      (
        <div>
          {(params.row.policyExpDate != null && params.row.policyExpDate != '') ? moment(params.row.policyExpDate).format("MM/DD/YYYY") : ""}
        </div>
      ),

    },
    {
      field: 'grossPremium',
      hideSortIcons: true,
      renderHeader: (params) => (
          <div>
              <span className="table-card-title">{params.colDef.headerName}</span>
              <RiExpandUpDownLine />
          </div>
      ),
      type: 'string',
      headerName: 'Gross Premium',
      flex: 0.5,
      align: 'left',
      headerAlign: 'center',
      filterOperators,
      renderCell: (params) => {
          let grossPremium = params.value || '0'; // Default to '0' if value is null or undefined
  
          // Trim any spaces and remove the dollar sign and commas
          let cleanedValue = grossPremium.replace(/[,\$]/g, "").trim();
  
          // If the value is in parentheses, it's negative, so handle that
          if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
              cleanedValue = "-" + cleanedValue.slice(1, -1); // Remove parentheses and add a negative sign
          }
  
          // Convert to number
          const numericValue = parseFloat(cleanedValue);
  
          // If numericValue is a valid number, format it; otherwise, return the original string
          return (
              <Box display="flex" alignItems="left" justifyContent="center">
                  {isNaN(numericValue) ? grossPremium : `${formatPrice(numericValue.toString())}`}
              </Box>
          );
      }
    },
  
    {
      field: 'status', hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ), type: 'string', headerName: 'Status', flex: 0.4, align: 'center', headerAlign: 'center' , filterOperators,
    },
    {
      field: 'Action', flex: 0.50,
      headerName: "Action",
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      disableExport: true,
      hide: user?.userRoles.split(",").includes("Super Admin") ? false : true,
      renderCell: (params) =>
      (
        <>
          {user?.userRoles.split(",").includes("Super Admin") ?
            <Link href={'/Admin/AddEditCustomerPolicyDetails/' + params.row.customerpolicyid} color="#1976D2">
                <IconButton
                    title="Update Policy"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </IconButton>
            </Link> : ''
          }
          <div style={{ display: 'flex', justifyItems: 'center', justifyContent: "center" }}>
            <IconButton
              title="Restore"
              onClick={() => closePolicy(params.row.customerpolicyid, 'getClosedFilingsFilterData', 5, 'Do you want to Restore to Filing Queue ?', false, true)}>
              <SettingsBackupRestoreIcon />
            </IconButton>
          </div>
        </>
      )
    },
  ]

  const handleExport = (option: string) => {
    var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
    getCustomerPolicyListbyTaxType("getCustomerPolicyListbyTaxType", customerPolicyIds, option);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer  >
        <GridToolbarFilterButton className="me-3 sky-blue" />
        <GridToolbarColumnsButton className="me-3 sky-blue" />
        {/* <GridToolbarExport className="me-3 sky-blue" /> */}
      </GridToolbarContainer>

    );
  }
  const exportOptions = [
    'Short', 'Detailed'
  ]
  const exportOptionsAdmin = [
    'Short', 'Detailed', 'All'
  ]
  function EditToolbar() {
    return (
      <Box

      >
        <div className=" p-2 d-flex justify-content-end datagrid-butons">
          <div className="ml-2"><CustomToolbar /></div>
          {/* <div style={{ display: "flex" }} className="align-items-center" >
            <button
              className=" me-2 active-btn-blue "
              onClick={handleExport}
              title="Export Customer Policies" >
              Export
            </button>
          </div> */}
          <div className="dropdowm-class ms-2" style={{ zIndex: "9" }}  >
            <MenuDropdown
              menuTitle={'Export'}
              items={user?.userRoles.split(",").includes("Super Admin") ? exportOptionsAdmin : exportOptions}
              onItemClick={handleExport} />
          </div>
        </div>
      </Box>
    );
  }
  const [selectedRows, setSelectedRows] = useState<NewCompletedFilingsModel[]>([]);

  const onRowsSelectionHandler = (ids: GridSelectionModel | undefined) => {

    const selectedIDs = new Set(ids);
    const uniqueRowsIds = new Set();
    const selectedRowData = NewCompletedFilingsCL.getCompletedFilings_Respdata.filter((row) => {
      if (selectedIDs.has(row?.rowNum ?? "") && !uniqueRowsIds.has(row?.rowNum ?? "")) {
        uniqueRowsIds.add(row?.rowNum ?? "");
        return true;
      }
      return false;
    });
    if (ids?.[0] != null || undefined) {
      setselectedState(
        NewCompletedFilingsCL.getCompletedFilings_Respdata.find((x) => x.customerpolicyid === Number(ids?.[0]))?.homeState ?? ""
      );
    }
    setSelectedRows(selectedRowData);
  };
  const isRowSelectable = (params: { row: { homeState: string; transactionTypeName: string }; }) => {
    if (selectedState === 'NY') {
      return params.row.homeState === 'NY';
    } else if (selectedState !== '') {
      return params.row.homeState !== 'NY';
    }
    return true;
  };

  const handleCompletedSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    sortModelRef.current = sortModel;
    //fetchCompletedDataSorting("getClosedFilings", 5, _pageSize, _currentPage, sortModel[0].field, sortModel[0].sort == undefined || sortModel[0].sort == null ? '' : sortModel[0].sort);
    fetchDataForFilter("getClosedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions)
  }, []);

  function CustomColumnMenu(props: GridColumnMenuProps) {
    const { hideMenu, currentColumn, color, ...other } = props;

    return (
      <GridColumnMenuContainer
        hideMenu={hideMenu}
        currentColumn={currentColumn}
        {...other}
      >
        <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
        <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
        <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  }


  return (
    <div style={{ height: "53vh" }}>
      {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
      <DataGridPro
        rowHeight={60}
        getRowId={(r) => r.rowNum}
        rowCount={NewCompletedFilingsCL?.totalCount}
        rows={NewCompletedFilingsCL.getCompletedFilings_Respdata}
        columns={columns}
        pageSize={_pageSize}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        pagination
        paginationMode="server"
        sortingMode="server"
        onPageChange={(newPage) => handleNextPreviousPage(newPage + 1)}
        onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
        onSortModelChange={handleCompletedSortModelChange}
        sortModel={sortModelRef.current}
        sortingOrder={['desc', 'asc']}

        rowsPerPageOptions={getRowsPerPageOptions(NewCompletedFilingsCL?.totalCount, _pageSize)}

        checkboxSelection={
          user?.userRoles.split(",").includes("Super Admin") ||
            user?.userRoles.split(",").includes("MISC Filer") ? true : false
        }



        filterMode="server"
        onFilterModelChange={onFilterChange}

        onSelectionModelChange={(newRowSelectionModel) => {
          onRowsSelectionHandler(newRowSelectionModel);

        }}
        components={{
          Toolbar: EditToolbar,
          ColumnMenu: CustomColumnMenu
        }}
      
        sx={{
          border: "0px",
          fontSize: "16px",
          '& .MuiDataGrid-columnHeaderTitle': {
            overflow: 'visible',
            whiteSpace: 'normal',
            lineHeight: 'normal',
            wordWrap: 'break-word',
            textOverflow: 'clip',
          },
          '& .MuiDataGrid-cell': {
            overflow: 'visible',
            whiteSpace: 'normal',
            lineHeight: 'normal',
            wordWrap: 'break-word',
            textOverflow: 'clip',
          },
        }}
      />
      < PolicyDocumentsPopup handleClose={handleClose} Open={Open} documentList={Formdata} />

    </div>






  )

}