import React, { useState } from "react"
import {
  DataGrid, GridColDef, GridSelectionModel, GridToolbar,
} from "@mui/x-data-grid"
import { RiExpandUpDownLine } from "react-icons/ri"
import { Box, FormControl, Grid, IconButton, LinearProgress, MenuItem, Select, SelectChangeEvent, } from "@mui/material"
import useAuth from "../../context/AuthProvider"
import moment from "moment"
import { ClientBillingReportModel } from "../../models/ClientBillingReportModel"
// import * as XLSX from 'xlsx'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2"
// import * as XLSX from 'xlsx-js-style';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { style } from "@mui/system"
import formatPrice from "../../helper/currencyFormatter"
import { useLocation } from "react-router-dom"
import { config } from "../../Constants"
import { StateReportsBillingModel } from "../../models/StateReportsBillingModel"
import { StateReportModel_Resp } from "../../models/StateReportModel"
import { EditStateReport } from "../AddEdit/EditStateReport"

interface Iprops {
  listData: StateReportsBillingModel[]
  onRemoveItems: (val: StateReportsBillingModel[]) => void
  setItemList: React.Dispatch<React.SetStateAction<StateReportsBillingModel[]>>
  selectedMonth: number
  handleFilterChange: any
  handleSortChange: any
  selectedPeriod: string
  selectedYear: string
  selectedTab: number
}

export const StateReportsList: React.FC<Iprops> = ({
  listData,
  onRemoveItems,
  setItemList,
  selectedMonth,
  handleFilterChange,
  handleSortChange,
  selectedPeriod,
  selectedYear,
  selectedTab,

}) => {
  const location = useLocation();
  const { user } = useAuth()
  const token = user?.token
  const [selectedRows, setSelectedRows] = useState<StateReportsBillingModel[]>()
  const [_pageSize, setPageSize] = React.useState(25);
  const [loading, setloading] = useState(false)
  const [Open, setOpen] = React.useState(false);
  const [openDoc, setOpenDoc] = React.useState(false);
  const [showDoc, setShowDoc] = React.useState(false);

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    const selectedIDs = new Set(ids)
    const selectedRowData = listData.filter(
      (row) => selectedIDs.has(row?.rowNum ?? "")
    )
    setSelectedRows(selectedRowData)
  }

  const handleDelete = () => {
    if (!selectedRows || selectedRows.length < 1) return
    onRemoveItems(selectedRows)
    setSelectedRows([])
  }
  const initialLBData: StateReportModel_Resp = {
    stateReportModel_Respdata: [],
    largeReport: undefined,
    completedPage: undefined,
    totalCount: 0,
    pageCount: 0,
    pageSize: 25,
    currentPage: 0

  };
  const handleEdit = (ILobData: StateReportModel_Resp) => {
    setFormData(ILobData);
    setOpenEdit(true);

  };
  const handleClose = () => {
    setOpen(false);
    setOpenDoc(false);
    setShowDoc(false);
    setOpenEdit(false);
    // fetchData();
  };

  const handleSubmit = () => {
    setOpenEdit(false);
  };
  const [Formdata, setFormData] = React.useState(initialLBData);
  const [OpenEdit, setOpenEdit] = React.useState(false);

  // Function to convert an image to a base64 string
  const getBase64ImageFromUrl = (url: string) => {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
      img.src = url;
    });
  };

  const printDataExcel33 = async () => {

    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, StateReportsBillingModel[]>);

    for (const [client, rows] of Object.entries(groupedByClient)) {
      const groupedData = rows.reduce((acc, row) => {
        const state = row.state;
        if (state) {
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(row);
        }
        return acc;
      }, {} as Record<string, StateReportsBillingModel[]>);

      let grandTotalFeeDue = 0;
      const clientName = client || "Unknown Client";

      const workbook = new ExcelJS.Workbook();

      for (const [state, rows] of Object.entries(groupedData)) {
        const worksheet = workbook.addWorksheet(state);



        // Add custom header fields starting from the 6th row
        worksheet.mergeCells('B2:C2');
        worksheet.getCell('B2').value = "Filed Transactions";
        worksheet.getCell('B2').font = { size: 20 };

        // worksheet.getCell('B2').alignment = { horizontal: 'center' };

        // worksheet.getCell('A7').value = "Client Name:";
        worksheet.getCell('B3').value = clientName;
        worksheet.getCell('B3').font = { size: 15 };
        worksheet.getCell('B4').value = `Start Date:  ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}`;

        worksheet.getCell('B4').value = `End Date:  ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}`;


        // Adding data headers
        const headers = Columns.filter(col => !col.hide).map(col => col.headerName);
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCCC' }
          };
        });

        headers.forEach((header, index) => {
          worksheet.getColumn(index + 1).width = Math.max(header?.length ?? 10, 15); // Initialize column widths
        });

        rows.forEach((row) => {
          const rowData: any[] = [];
          Columns.forEach((col, colIndex) => {
            if (!col.hide) {
              let value = (row as any)[col.field];
              if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                value = value ? "✔" : "";
              } else if (col.field === 'period') {
                value = value ? moment(value).format("MMMM") : '';
              } else {
                value = value !== undefined && value !== null ? value : "";
              }

              rowData.push(value);

              // Update column width
              if (value && value.toString().length > (worksheet.getColumn(colIndex + 1).width ?? 15)) {
                worksheet.getColumn(colIndex + 1).width = value.toString().length;
              }

              if (col.field === 'feeDue') {
                grandTotalFeeDue += parseFloat(value) || 0;
              }
            }
          });
          worksheet.addRow(rowData);
        });
        worksheet.getCell(`G` + (rows.length + 6)).value = `Fee Due: `;
        worksheet.getCell(`G` + (rows.length + 6)).font = { size: 15, color: { argb: "FF0000" } };
        worksheet.getCell(`H` + (rows.length + 6)).value = grandTotalFeeDue.toFixed(2);
        worksheet.getCell(`H` + (rows.length + 6)).font = { size: 15, color: { argb: "FF0000" } };
        // Apply striped table formatting
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 10) { // Apply styles to data rows
            row.eachCell((cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: rowNumber % 2 === 0 ? 'FFDDDDDD' : 'FFFFFFFF' }
              };
            });
          }
        });


        // Add image to worksheet
        const base64Image = await getBase64ImageFromUrl('/assets/brand/ExcelLogo.png'); // Replace with your image path
        const imageId = workbook.addImage({
          base64: base64Image.split(',')[1], // Extract base64 data
          extension: 'png',
        });
        worksheet.addImage(imageId, 'A1:A2');


      }

      // Convert the workbook to a Blob
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Use FileSaver.js to save the file
      saveAs(blob, 'Report.xlsx');
    }
  };

  const printDataExcelOld = async () => {

    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;

    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, StateReportsBillingModel[]>);

    for (const [client, rows] of Object.entries(groupedByClient)) {
      const groupedData = rows.reduce((acc, row) => {
        const state = row.state;
        if (state) {
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(row);
        }
        return acc;
      }, {} as Record<string, StateReportsBillingModel[]>);

      let grandTotalFeeDue = 0;
      const clientName = client || "Unknown Client";

      for (const [state, rows] of Object.entries(groupedData)) {
        // Load the existing template
        console.log("dataToPrint", dataToPrint);
        const workbook = new ExcelJS.Workbook();
        const response = rows.length > 19 ? (await fetch('/assets/brand/ClientBillingTemplateNew1.xlsx')) : await fetch('/assets/brand/ClientBillingTemplateNew.xlsx'); // Replace with your template path
        const arrayBuffer = await response.arrayBuffer();
        await workbook.xlsx.load(arrayBuffer);

        const worksheet = workbook.getWorksheet(1);

        if (worksheet != null) {
          worksheet.getCell('B3').value = clientName;
          worksheet.getCell('B4').value = `Star tDate:  ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}`
          worksheet.getCell('B5').value = `EndDate:  ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}`



          // add a table to a sheet

          rows.forEach((row, rowIndex) => {


            Columns.forEach((col, colIndex) => {

              if (!col.hide) {
                let value = (row as any)[col.field];
                if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                  value = value ? "✔" : "";
                } else if (col.field === 'period') {
                  value = value ? moment(value).format("MMMM") : '';
                }
                else {

                  value = value !== undefined && value !== null ? value : "";
                }
                if (col.field === 'feeDue') {
                  grandTotalFeeDue += parseFloat(value) || 0;
                }

                worksheet.getRow(rowIndex + 7).getCell(colIndex).value = value;

                // worksheet.getRow(rowIndex + 7).getCell(colIndex). style =  {fill: {type: 'pattern', pattern: 'solid', bgColor: {argb: '#ffa5a5a5'}}}

              }

            });

            // worksheet.addRow(rowData);
          });
          if (rows.length > 19) {
            worksheet.getCell(`G` + (rows.length + 8)).value = 'Fee Due';
            worksheet.getCell(`G` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
            worksheet.getCell(`H` + (rows.length + 8)).value = grandTotalFeeDue.toFixed(2);
            worksheet.getCell(`H` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
            // worksheet.getCell(`H` + (rows.length + 6)).fill = { bgColor:{ argb: "#ffffff" } , pattern : 'solid'  , type: 'pattern', };
          }



        } else {
          alert("Something went wrong");
          return;
        }

        // Convert the workbook to a Blob
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Use FileSaver.js to save the file
        saveAs(blob, 'Report.xlsx');
      }
    }
  };


  const printDataExcel = async () => {
    let dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    console.log("dataToPrint", dataToPrint);
    //  const response = await fetchExportData(action, selectedOptions, ReportModule?.totalCount, 1, completedPage);
    // Group by client first

    let grandTotalFeeDue = 0;

    try {
      const workbook = new ExcelJS.Workbook();
      const response = await fetch('/assets/brand/ClientBillingTemplateNewUpdated_new_new.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);
      const worksheet = workbook.getWorksheet(1);
      if (!worksheet) return alert("Something went wrong");

      let rowIndex = 3;
      let filteredColumns = Columns.filter(col => col.headerName && col.headerName !== 'No' && col.headerName !== 'startDate' && col.headerName !== 'endDate')
      console.log("filtered column", filteredColumns)
      const groupedByClient = dataToPrint.reduce((acc, row) => {
        const client = row.partnerName;
        if (client) {
          if (!acc[client]) acc[client] = [];
          acc[client].push(row);
        }
        return acc;
      }, {} as Record<string, StateReportsBillingModel[]>);
      const stateColumnIndex = filteredColumns.findIndex(col => col.headerName === 'state');
      if (stateColumnIndex !== -1) filteredColumns.unshift(filteredColumns.splice(stateColumnIndex, 1)[0]);
      const columnHeaders = filteredColumns.map(col => col.headerName);

      const addHeaderRow = (rowIdx: number) => {
        const headerRow = worksheet.getRow(rowIdx);
        columnHeaders.forEach((header, colIndex) => {
          if (header !== "Edit") {
            const cell = headerRow.getCell(colIndex + 1);
            cell.value = header;
            cell.font = { bold: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '808080' } }; // Header row in solid grey}
          }
        });
        return rowIdx + 1;
      };

      // Only process the first client
      const [firstClientName, firstClientRows] = Object.entries(groupedByClient)[0] || [];

      if (firstClientName && firstClientRows) {
        // Group rows by state for the first client
        const groupedByState = firstClientRows.reduce((acc, row) => {
          const state = row.stateName; // Assuming `state` exists in your data
          if (state) {
            if (!acc[state]) acc[state] = [];
            acc[state].push(row);
          }
          return acc;
        }, {} as Record<string, StateReportsBillingModel[]>);

        let clientTotalFeeDue = 0;
        worksheet.getRow(rowIndex).getCell(2).value = firstClientName;
        worksheet.getRow(rowIndex).getCell(2).font = { bold: false, size: 14 };
        rowIndex++;

        // Insert Start Date into column B
        worksheet.getRow(rowIndex).getCell(2).value = `Start Date: ${selectedPeriod === 'Monthly'
          ? moment(new Date(parseInt(selectedYear, 10), selectedMonth - 1, 1)).format("MM/DD/YYYY")
          : moment(new Date(parseInt(selectedYear, 10), 0, 1)).format("MM/DD/YYYY")}`;
        worksheet.getRow(rowIndex).getCell(2).font = { bold: false };
        rowIndex++;

        // Set End Date
        worksheet.getRow(rowIndex).getCell(2).value = `End Date: ${selectedPeriod === 'Monthly'
          ? moment(new Date(parseInt(selectedYear, 10), selectedMonth, 0)).format("MM/DD/YYYY")
          : (parseInt(selectedYear, 10) === new Date().getFullYear()
            ? moment(new Date()).format("MM/DD/YYYY")
            : moment(new Date(parseInt(selectedYear, 10), 11, 31)).format("MM/DD/YYYY"))}`;
        worksheet.getRow(rowIndex).getCell(2).font = { bold: false };
        rowIndex++;

        // Loop through each state and print its data
        for (const [stateName, rows] of Object.entries(groupedByState)) {
          rowIndex = addHeaderRow(rowIndex); // Add the header for the state
          let colorCycleIndex = 0; // Reset the color cycle for each state group

          rows.forEach((row) => {
            console.log("newFilteredColumns", filteredColumns);
            const newFilteredColumns = filteredColumns.filter((val) => val.field != "stateRule")
            newFilteredColumns.forEach((col, colIndex) => {
              console.log("newFilteredColumns", filteredColumns);
              if (col.field == "largeReportFee") {
                delete col.hide;
              }

              if (!col.hide) {
                let value = (row as any)[col.field];
                console.log(col.field, value)
                if (col.field === "payment" || col.field === "reportRequired" || col.field === "mainLicensee") value = value ? "true" : "false";
                else if (col.field === 'dateFinalReportSentToClient') value = value ? moment(value).format("MM-DD-YYYY") : '';
                else if (col.field == 'taxReported' || col.field == 'largeReportFee') value = formatPrice(value);
                else value = value !== undefined && value !== null ? value : "";

                if (col.field === 'feeDue') {
                  const fee = parseFloat(value) || 0;
                  clientTotalFeeDue += fee;
                  grandTotalFeeDue += fee;
                }

                const cell = worksheet.getRow(rowIndex).getCell(colIndex + 1);

                cell.value = (col.field === "feeDue") ? Number(value) : value;
                if (col.field === "feeDue") cell.numFmt = '"$"#,##0.00';
                // Apply alternating fill colors: white, light gray, dark gray
                const fillColors = ['FFFFFF', 'ededed', 'd9d9d9'];
                const fillColor = fillColors[colorCycleIndex % 3];
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };
              }
            });

            rowIndex++;
            colorCycleIndex++; // Increment to apply the next color in the cycle
          });
        }
      }

      worksheet.getCell(`L${rowIndex}`).value = 'Total Fee Due';
      worksheet.getCell(`L${rowIndex}`).font = { bold: true, size: 15, color: { argb: "FF0000" } };
      worksheet.getCell(`M${rowIndex}`).value = grandTotalFeeDue;
      worksheet.getCell(`M${rowIndex}`).numFmt = '"$"#,##0.00';
      worksheet.getCell(`M${rowIndex}`).font = { bold: true, size: 15, color: { argb: "FF0000" } };


      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'StateReport.xlsx');
    } catch (error) {
      console.error("Error generating the Excel file:", error);
    }
  };



  const printDataPdf = async (isMonthEndBilling = false) => {
    var dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    setloading(false);
    if (dataToPrint.length == 0) {
      const printWindow = window.open("", "_blank");
    
      let pdfHeading;
    
      switch (selectedTab) {
        case 1:
          pdfHeading = "Upcoming State Reports and Payments";
          break;
        case 2:
          pdfHeading = "Completed State Reports and Payments";
          break;
        case 3:
          pdfHeading = "Outstanding State Reports and Payments";
          break;
        default:
          pdfHeading = "State Reports and Payments";
          break;
      }
    
      if (printWindow) {
        printWindow.document.write(`
          <html>
            <head>
              <title>${pdfHeading}</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 20px;
                }
                .header {
                  text-align: left;
                  margin-bottom: 100px;
                }
                .header h1 {
                  margin: 0;
                  font-size: 24px;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                  table-layout: fixed; /* Ensure consistent column widths */
                  margin-top: 20px;
                }
                th {
                  background-color: #918f98 !important; /* Darker gray for the header */
                  font-weight: bold;
                  text-align: center;
                  padding: 8px;
                  -webkit-print-color-adjust: exact; /* Ensure header color prints correctly */
                }
                td {
                  padding: 8px;
                  text-align: center;
                  vertical-align: middle;
                  word-wrap: break-word;
                }
                tbody tr:nth-child(even) {
                  background-color: #f9f9f9; /* Light gray for even rows */
                }
                tbody tr:nth-child(odd) {
                  background-color: #ffffff; /* White for odd rows */
                }
                .sub-header {
                  margin-top: 200px;
                  margin-left: 100px;
                  font-size: 14px;
                  line-height: 1.6;
                  text-align: left;
                }
                .sub-header p {
                  margin: 5px 0;
                }
                @media print {
                  th {
                    background-color: #e0e0e0 !important; /* Ensure header color prints correctly */
                    -webkit-print-color-adjust: exact;
                  }
                  tbody tr:nth-child(even) {
                    background-color: #f9f9f9 !important; /* Light gray for even rows in print */
                  }
                  tbody tr:nth-child(odd) {
                    background-color: #ffffff !important; /* White for odd rows in print */
                  }
                }
              </style>
            </head>
            <body>
              <div class="header">
                <div style="display: flex; align-items: center;">
                  <img src="/assets/brand/ExcelLogo.png" alt="Logo" style="height:60px; width:70px; margin-right: 10px;">
                  <h1>${pdfHeading}</h1>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
        `);
    
        // Dynamically generate table headers
        Columns.forEach((col) => {
          if (!col.hide) {
            printWindow.document.write(`<th>${col.headerName}</th>`);
          }
        });
    
        // Insert the Follow-Up Dates and Notes section after the table headers
        printWindow.document.write(`
                  </tr>
                </thead>
              </table>
              <div class="sub-header">
                <p><strong>Follow Up Dates: </p>
                <p><strong>Notes To Client: </p>
              </div>
            </body>
          </html>
        `);
    
        printWindow.document.close();
        printWindow.print();
      }
    }
    
    
    
    
    else {
      const groupedByClient = dataToPrint.reduce((acc, row) => {
        const client = row.partnerName;
        if (client) {
          if (!acc[client]) {
            acc[client] = [];
          }
          acc[client].push(row);
        }
        return acc;
      }, {} as Record<string, StateReportsBillingModel[]>);

      for (const [client, rows] of Object.entries(groupedByClient)) {
        const groupedData = rows.reduce((acc, row) => {
          const state = row.stateName;
          if (state) {
            if (!acc[state]) {
              acc[state] = [];
            }
            acc[state].push(row);
          }
          return acc;
        }, {} as Record<string, StateReportsBillingModel[]>);

        let grandTotalFeeDue = 0;
        const printWindow = window.open("", "_blank");

        let pdfHeading;

        switch (selectedTab) {
          case 1:
            pdfHeading = "Upcoming State Reports and Payments";
            break;
          case 2:
            pdfHeading = "Completed State Reports and Payments";
            break;
          case 3:
            pdfHeading = "Outstanding State Reports and Payments";
            break;
          default:
            pdfHeading = "State Reports and Payments";
            break;
        }

        if (printWindow) {
          const clientName = client;

          printWindow.document.write(`
            <html>
              <head>
                <title>Print Report</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 20px;
                  }
                  .header {
                    text-align: left;
                    margin-bottom: 20px;
                  }
                  .header h1 {
                    margin: 0;
                    font-size: 24px;
                  }
                  .header p {
                    margin: 0;
                    font-size: 14px;
                  }
                  table {
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: fixed; /* Ensure consistent column widths */
                  }

                  th {
                    background-color: #918f98 !important; /* Even darker gray for the header */
                    font-weight: bold;
                    text-align: center;
                    padding: 8px;
                    -webkit-print-color-adjust: exact; /* Ensure header color prints correctly */
                  }

                  td {
                    padding: 8px;
                    text-align: center;
                    vertical-align: middle;
                    word-wrap: break-word;
                  }

                  tbody tr:nth-child(even) {
                    background-color: #f9f9f9; /* Light gray for even rows */
                  }

                  tbody tr:nth-child(odd) {
                    background-color: #ffffff; /* White for odd rows */
                  }

                  .total-row {
                    font-weight: bold;
                    background-color: #e0e0e0; /* Same light gray as header for consistency */
                  }

                  .fee-due {
                    color: black;
                    font-weight: normal;
                    text-align: center;
                  }
                  .fees-due {
                    color: black;
                    font-weight: bold;
                    text-align: center;
                    color: red;
                  }

                  @media print {
                    th {
                      background-color: #e0e0e0 !important; /* Ensure header color prints correctly */
                      -webkit-print-color-adjust: exact;
                    }
                    tbody tr:nth-child(even) {
                      background-color: #f9f9f9 !important; /* Light gray for even rows in print */
                    }
                    tbody tr:nth-child(odd) {
                      background-color: #ffffff !important; /* White for odd rows in print */
                    }
                  }
                </style>
              </head>
              <body>
                <div class="header" style="margin-bottom: 7px">
                  <div style="display: flex; align-items: center;">
                    <img src="/assets/brand/ExcelLogo.png" alt="Logo" style="height:60px; width:70px; margin-right: 5px;">
                    <h1 style="font-size: 30px; font-family: 'Calibri Light', 'Gill Sans', 'Open Sans', Geneva, Helvetica, Arial, sans-serif; font-weight: 1000;">${pdfHeading}</h1>
                  </div>
                  <h4 style="font-weight: lighter; margin:0 0 0 70px; font-size: 20px;">${clientName}</h4>
                  <div class="date-container" style="margin-top: 10px; margin-left: 70px;">
                    <p style="margin: 0">
                      Start Date: 
                      ${selectedPeriod === 'Monthly'
              ? moment(new Date(parseInt(selectedYear, 10), selectedMonth - 1, 1)).format("MM/DD/YYYY")
              : moment(new Date(parseInt(selectedYear, 10), 0, 1)).format("MM/DD/YYYY")}
                    </p>
                    <p style="margin-top: 10px;">
                      End Date: 
                      ${selectedPeriod === 'Monthly'
              ? moment(new Date(parseInt(selectedYear, 10), selectedMonth, 0)).format("MM/DD/YYYY")
              : (parseInt(selectedYear, 10) === new Date().getFullYear()
                ? moment(new Date()).format("MM/DD/YYYY")
                : moment(new Date(parseInt(selectedYear, 10), 11, 31)).format("MM/DD/YYYY"))}
                    </p>
                  </div>
                </div>
          `);

          for (const [state, rows] of Object.entries(groupedData)) {
            printWindow.document.write(`
              <table>
                <thead>
                  <tr>
            `);

            Columns.forEach((col) => {
              console.log("Columnsss", Columns);
              if (col.field === "stateRule") {
                col.hide = true;
              }
              if (col.field == "largeReportFee") {
                delete col.hide;
              }
              if (!col.hide) {
                printWindow.document.write(`<th>${col.headerName}</th>`);
              }
            });

            printWindow.document.write(`
                  </tr>
                </thead>
                <tbody>
            `);

            rows.forEach((row) => {
              printWindow.document.write("<tr>");
              Columns.forEach((col) => {
                if (!col.hide) {
                  const value = (row as any)[col.field];
                  let formattedValue = value !== undefined && value !== null ? value : "";

                  if (col.field === "payment" || col.field === "reportRequired" || col.field === "mainLicensee") {
                    // Render checkbox as an inline SVG
                    formattedValue = value
                      ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="black" viewBox="0 0 24 24">
                          <rect width="20" height="20" x="2" y="2" fill="none" stroke="black" stroke-width="2" rx="2" ry="2"></rect>
                          <path d="M7 13l3 3 7-7" stroke="black" stroke-width="2" fill="none"></path>
                        </svg>`
                      : `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="black" viewBox="0 0 24 24">
                          <rect width="20" height="20" x="2" y="2" fill="none" stroke="black" stroke-width="2" rx="2" ry="2"></rect>
                        </svg>`;
                    printWindow.document.write(`<td style="text-align: center;">${formattedValue}</td>`);
                  } else if (col.field === "feeDue" || col.field === "taxReported" || col.field === "largeReportFee") {
                    formattedValue = formattedValue === "" ? '0.00' : formattedValue;
                    printWindow.document.write(`<td class="fee-due">${"$" + formattedValue}</td>`);
                    grandTotalFeeDue += parseFloat(value) || 0;
                  } else if (col.field === "dateFinalReportSentToClient" || col.field === "dueDate") {
                    formattedValue = value ? moment(value).format("MM-DD-YYYY") : "";
                    printWindow.document.write(`<td style="text-align: center;">${formattedValue}</td>`);
                  } else {
                    printWindow.document.write(`<td>${formattedValue}</td>`);
                  }
                }
              });
              printWindow.document.write("</tr>");
            });
          }

          printWindow.document.write(`
              <table> 
                <tfoot>
                  <tr class="total-row">
                    <td colspan='${Columns.length - 1}' style='text-align: right; font-weight: bold; color: red;'>Fees Due</td>
                    <td class="fees-due", style>${"$" + grandTotalFeeDue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  </tr>
                </tfoot>
              </table>
            </body>
            </html>
          `);

          printWindow.document.close();
          printWindow.print();

          break;
        }
      }
    }
  };



  const [selectedOption, setSelectedOption] = useState<string>('Print');
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);

    printData(event.target.value);

  };

  const printData = (option: string, isMonthEndBilling: boolean = false) => {

    if (option === 'excel') {
      printDataExcel();
    } else if (option === 'pdf') {
      // printDataPdf(true);
      isMonthEndBilling ? printDataPdf(true) : printDataPdf();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No option selected',
        text: 'Please select an option to proceed.',
      });
    }
    setSelectedOption('0');
  };

  function EditToolbar() {
    const isMonthEndBillingPage = location.pathname === '/Billing/MonthEndBillingModule';
    const [apiResponse, setApiResponse] = useState(null); // To store the API response

    // Function to handle API call
    const handleMonthEndBillingClick = async () => {
      printData('pdf', true);
    };
    return (
      <React.Fragment>
        <Box display="flex" sx={{}}>
          <Grid
            container
            display="flex"
            margin={"10px 0px"}
            justifyContent={"space-between"}
          >

            <Grid item xs={12} md={6}>
              <GridToolbar />
            </Grid>
            <Grid item xs={12} md={6}>

              <Grid container display='flex' justifyContent={'flex-end'}>
                <Grid item xs={4} sm={4} md={1.5} minWidth={130} className="select-input-border">
                  {isMonthEndBillingPage && user?.userRoles.split(",").includes("Super Admin") && (
                    <button className="active-btn-blue" style={{ padding: 1, maxHeight: 45, height: 40 }} onClick={handleMonthEndBillingClick}>
                      Month End Billing
                    </button>
                  )}
                </Grid>
                {/* <Grid item xs={4} sm={4} md={1.5} minWidth={130} className=" select-input-border">
                  {user?.userRoles.split(",").includes("Super Admin") ? (
                    <button
                      onClick={handleDelete}
                     className="active-btn-blue" 
                     style = {{padding: 1,  maxHeight: 45, height: 40}}
                    >
                      Remove
                    </button>
                  ) : (
                    ""
                  )}

                </Grid> */}
                <Grid item xs={4} sm={4} md={1.5} minWidth={130} className=" select-input-border">

                  {user?.userRoles.split(",").includes("Super Admin") ? (

                    <FormControl fullWidth className="">
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        id="test-select-label"
                        autoWidth
                        size="small"
                        label={null}
                        className="active-btn-print"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                        style={{ margin: 0 }}
                        renderValue={(selected) => {
                          if (selected === '0') {
                            return 'Print';
                          }
                          return selected;
                        }}
                      >
                        {/* <MenuItem key={0} value={0}> {'Print'} </MenuItem> */}
                        <MenuItem value={'excel'}>Excel</MenuItem>
                        <MenuItem value={'pdf'}>PDF</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ""
                  )}
                </Grid>


              </Grid>

            </Grid>
          </Grid>
        </Box>
        <EditStateReport handleClose={handleClose} Open={OpenEdit} StateRuleMatrixCL={Formdata} handleSubmit={handleSubmit} initValues={initialLBData} isStateReport={false} />
      </React.Fragment>
    );
  }
  const Columns: GridColDef[] = [
    {
      field: 'paymentFilingScheduleId',
      type: 'number',
      headerName: 'No',
      hide: true,
      hideable: false,
      disableExport: true,
    },
    {
      field: "stateName",
      type: "text",
      headerName: "State",
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "reportName",
      type: "text",
      headerName: "Report Name",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "period",
      type: "text",
      headerName: "Period",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "licensee",
      type: "text",
      headerName: "Licensee",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "dateFinalReportSentToClient",
      type: "text",
      headerName: "Date Completed",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "dueDate",
      type: "text",
      headerName: "Due Date",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "payment",
      type: "text",
      headerName: "Payment Required",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "paymentType",
      type: "text",
      headerName: "Payment Type",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "reportRequired",
      type: "text",
      headerName: "Report Required",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
      editable: true, // Make the feeDue column editable
    },
    {
      field: "mainLicensee",
      type: "text",
      headerName: "Main Licensee",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
      editable: true, // Make the feeDue column editable
    },
    {
      field: "taxReported",
      type: "text",
      headerName: "Tax Reported",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => formatPrice(params.value),
      editable: true, // Make the feeDue column editable
    },
    {
      field: "largeReportFee",
      type: "text",
      headerName: "Large Report",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) =>
        formatPrice(typeof params.value === 'string' ? params.value : String(params.value)),
      editable: true, // Make the feeDue column editable
    },
    {
      field: "feeDue",
      type: "text",
      headerName: "Fee Due",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) =>
        formatPrice(typeof params.value === 'string' ? params.value : String(params.value)),
      editable: true, // Make the feeDue column editable
    },
    {
      field: 'stateRule',
      flex: 0.4,
      headerName: "Edit",
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          <IconButton
            color={params.row.largeReport ? "primary" : "default"} // Shaded form when false
            onClick={() => {
              if (params.row.largeReport) { // Prevent click if false
                handleEdit(params.row);
              }
            }}
            disabled={!params.row.largeReport} // Disable button when false
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z"
                stroke={params.row.largeReport ? "#02A4E3" : "#B0B0B0"} // Gray color when false
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
      ),
    }

  ];

  //   const ColumnsForDiligents: GridColDef[] = [
  //     {
  //       field: 'rowNum',
  //       type: 'number',
  //       headerName: 'No',
  //       hide: true,
  //       hideable: false,
  //       disableExport: true,
  //     },

  //     {
  //       field: "state",
  //       type: "text",
  //       headerName: "State",
  //       minWidth: 140,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => params.value,
  //     },
  //     {
  //       field: "invoiceNumber",
  //       type: "text",
  //       headerName: "Invoice Number",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => params.value,
  //     },
  //     {
  //       field: "insuredName",
  //       type: "text",
  //       headerName: "Insured Name",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //     },
  //     {
  //       field: "policyNumber",
  //       type: "text",
  //       headerName: "Policy Number",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => params.value,
  //     },
  //     {
  //       field: "needDiligentSearch",
  //       type: "text",
  //       headerName: "Need Diligent Search",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => params.value,
  //     },
  //     {
  //       field: "policyEffDate",
  //       type: "text",
  //       headerName: "PolicyEffDate",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => {
  //         const dateValue = params.value;

  //         if (dateValue) {
  //           const date = new Date(dateValue);
  //           const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  //           const day = String(date.getDate()).padStart(2, '0');
  //           const year = date.getFullYear();

  //           return `${month}/${day}/${year}`;
  //         }

  //         return "";
  //       },
  //       // valueGetter: (params) => params.value,
  //     },
  //     {
  //       field: "policyExpDate",
  //       type: "text",
  //       headerName: "PolicyExpDate",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => {
  //         const dateValue = params.value;

  //         if (dateValue) {
  //           const date = new Date(dateValue);
  //           const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  //           const day = String(date.getDate()).padStart(2, '0');
  //           const year = date.getFullYear();

  //           return `${month}/${day}/${year}`;
  //         }

  //         return "";
  //       },
  //       // valueGetter: (params) => params.value,
  //     },
  //     {
  //       field: "transactionType",
  //       type: "text",
  //       headerName: "Transaction Type",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => params.value,
  //     },
  //     {
  //       field: "grossPremium",
  //       type: "text",
  //       headerName: "Gross Premium",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => formatPrice(params.value),
  //       editable: true, // Make the feeDue column editable
  //     },
  //     {
  //       field: "feeDue",
  //       type: "text",
  //       headerName: "Fee Due",
  //       minWidth: 200,
  //       headerAlign: "center",
  //       align: "center",
  //       hideSortIcons: true,
  //       renderHeader: (params) => (
  //         <div>
  //           <span className="table-card-title">{params.colDef.headerName}</span>
  //           <RiExpandUpDownLine />
  //         </div>
  //       ),
  //       valueGetter: (params) => formatPrice(params.value),
  //       editable: true, // Make the feeDue column editable
  //     },
  //   ];



  const handleRowEditCommit = (row: any) => {

    let updatedRow = row.row
    let AllRec = [...listData];

    let exsistinfRec = AllRec.find(x => x.rowNum === updatedRow.rowNum);

    if (exsistinfRec != undefined) {
      exsistinfRec.feeDue = updatedRow.feeDue;
      setItemList(AllRec);
    }

    return updatedRow;
  };



  return (
    <>
      <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
        {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
      </div>
      <DataGrid
        getRowId={(r) => r.rowNum}
        rows={listData}
        columns={Columns}
        pageSize={_pageSize}
        pagination
        rowsPerPageOptions={[10, 25, 50]}
        editMode="row"
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onRowEditStop={handleRowEditCommit}
        checkboxSelection={
          user?.userRoles.split(",").includes("Super Admin") ? true : false
        }
        filterMode="server"
        onFilterModelChange={handleFilterChange} // For filtering
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
        onSortModelChange={handleSortChange} // Add this line for sorting
        onSelectionModelChange={
          user?.userRoles.split(",").includes("Super Admin")
            ? (newRowSelectionModel) => {
              onRowsSelectionHandler(newRowSelectionModel)
            }
            : undefined
        }
        components={{
          Toolbar: EditToolbar,
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          fontFamily: "'Proxima Nova', sans-serif",
          border: "0px",
          fontSize: "16px",
        }}
      // experimentalFeatures={{ newEditingApi: true }}
      />
    </>
  )
}
