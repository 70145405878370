// Constants.js

export const prod = {
  url: {
    API_URL: "https://filemule-api.azurewebsites.net/api"
    // API_URL: "https://filemule-api-uat.azurewebsites.net/api"
    //API_URL: "https://filemule-api-live.azurewebsites.net/api"

    // API_URL: "https://api.filemule.io/api"

  }

}

export const dev = {
  url: {
    API_URL: "http://localhost:5274/api"
  }
}

export const live = {
  url: {
    API_URL: "http://filemule-api-live.azurewebsites.net/api"
  }
}

export const uat = {
  url: {
    API_URL: "https://filemule-api-uat.azurewebsites.net/api"
  }
}

export const staging = {
  url: {
    API_URL: "https://filemule-api.azurewebsites.net/api"
  }
}

console.log('process.env.REACT_APP_ENV :- ', process.env.REACT_APP_ENV);


export const config = process.env.REACT_APP_ENV === "Development" ? dev : process.env.REACT_APP_ENV === "Production" ? live : process.env.REACT_APP_ENV === "Uat" ? uat : process.env.REACT_APP_ENV === "Staging" ? staging : prod; 