import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form, Formik, useFormik } from "formik";
import * as yup from 'yup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TaxTransactionReportModel, TaxTransactionReportModel_Resp } from "../models/TaxTransactionReportModel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from 'moment';
import Swal from 'sweetalert2';

interface Iprops {
    handleClose: () => void;
    open: boolean;
    currentStatus?: string;
    handleSubmit: (dateCompleted: string) => void;
    currentPolicySubStatus?: string
    selectedRows?: TaxTransactionReportModel[] | null
}
const StateReportMarkCompleted = ({ open, handleClose, handleSubmit, selectedRows = [] }: Iprops) => {
    const [dateCompleted, setDateCompleted] = useState<string>('');
    const shouldDisableDate = (date: Date) => {
        const minDate = new Date('1900-01-01')
        const maxDate = new Date()
        return date < minDate || date > maxDate;
    };
    const handleDateCompleted = (event: any) => {
        if (event) {
            let formattedDate = '';
            try {
                const date = new Date(event);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);  // Add leading zero if needed
                const day = ('0' + date.getDate()).slice(-2);           // Add leading zero if needed
                const year = date.getFullYear();
                
                // Construct the formatted date as MM/dd/yyyy
                formattedDate = `${month}/${day}/${year}`;
            } catch {
                formattedDate = event.toLocaleString();
            }
            setDateCompleted(formattedDate);
        }
    };
    useEffect(() => {
        if (selectedRows && selectedRows.length === 1) {
            const customerPolicyId = selectedRows[0].customerpolicyid;
        }
    }, [selectedRows]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <React.Fragment>
                    <Formik
                        initialValues={{dateCompleted: null}}
                        onSubmit={() => {
                             handleSubmit(dateCompleted); // Pass the form data to the handler
                        }
                        }
                    >
                        {({ values, isSubmitting }) => (
                            <Form className='main-form' >
                                <DialogContent >
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} marginTop={3}>
                                                <FormHelperText sx={{ width: '100%', minWidth: '400px' }} className='formtexthelper-heading' >Date Completed</FormHelperText>
                                                <DatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    label={null}
                                                    className="select-input-border text-input-box"
                                                    shouldDisableDate={shouldDisableDate}
                                                    value={dateCompleted ?? ''}
                                                    onChange={handleDateCompleted}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <button className='btn-2 btn d-flex btn-primary search-btn' type='button' onClick={handleClose}>Cancel</button>
                                    <button className='btn btn-primary active-btn-blue search-btn' type='submit'>Submit</button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>
            </Dialog>
        </LocalizationProvider>



    );
};

export default StateReportMarkCompleted;
